<template>
  <div v-if="isshowgrid">
    <va-card title="Content List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="row">
          <va-select
            class="flex xs4"
            placeholder="Select Filter"
            v-model="filter"
            textBy="id"
            @input="getFilter(filter)"
            :options="FilterArr"
            :error="!!filterErrors.length"
            :error-messages="filterErrors"
          />
          <va-input
            class="flex xs4"
            v-if="filterFlag"
            v-model="filterData"
            placeholder="Enter Filter Data"
          />
          <p><va-button style="margin-top: 22px;" flat small color="gray"  v-if="filterFlag" icon="fa fa-play" @click="getDataByFilter()" ></va-button></p>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="Title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Title</span>
            <va-input
              v-model="title"
              placeholder="Enter Title"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Genres</span>
            <va-input
              v-model.trim="genres"
              placeholder="Enter Geners"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Service Type</span>
            <va-input
              v-model="service_type"
              placeholder="Enter Service Type"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">SC Type</span>
            <va-input
              v-model.trim="sc_type"
              placeholder="Enter SC Type"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Language</span>
            <va-input
              v-model="language"
              placeholder="Enter Language"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Director</span>
            <va-input
              v-model.trim="director"
              placeholder="Enter Director"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Age Limit</span>
            <va-input
              v-model.trim="age_limit"
              placeholder="Enter Age Limit"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Shows Data Title</span>
            <va-input
              v-model.trim="shows_data_title"
              placeholder="Enter Show Data Title"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Rating</span>
            <va-input
              v-model.trim="rating"
              placeholder="Enter Rating"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">LCN Number</span>
            <va-input
              v-model.trim="channel_lcn_number"
              placeholder="Enter LCN Number "
              type="text"
              pattern="[0-9]*"
              maxlength=10
              
            /> 
            <va-checkbox
              v-model="is_active"
              label="Is Active"
            />
            <va-checkbox
              v-model="is_banner"
              label="Is Banner"
            />
            <va-checkbox
              v-model="is_playable"
              label="Is Playable"
            />
            <va-checkbox
              v-model="is_home"
              label="Is Home"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createGenres()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateGenres()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'content',
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: true,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      filterFlag: false,
      showRemoveModal: false,
      genres: '',
      is_active: false,
      is_banner: false,
      is_playable: false,
      is_home: false,
      filterData: '',
      Title: '',
      filter: '',
      sc_category: '',
      contentData: [],
      FilterArr: [{ id: 'SC Type' }],
      filterErrors: [],
    }
  },
  computed: {
    fields () {
      return [{
	      name: 'sno',
	      title: 'S.NO',
      },
      {
        name: 'title',
        title: 'Title',
      },
      {
        name: 'shows_data_title',
        title: 'Shows Data Title',
      },
      {
        name: 'genres',
        title: 'Genres',
      },
      {
        name: 'sc_type',
        title: 'SC Type',
      },
      {
        name: 'language',
        title: 'Language',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.contentData)
    },
  },
  methods: {
    getFilter (data) {
    	this.filterFlag = true
    },
    updateGenres () {
     
      var payload = {
        sc_type: this.sc_type,
        is_active: this.is_active,
 	      is_banner: this.is_banner,
        is_playable: this.is_playable,
        is_home: this.is_home,
        channel_lcn_number:this.channel_lcn_number
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/content/' + this.content_id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
          this.getDataByFilter();
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    getDataByFilter () {
    	var obj = {
    		Title: 'title',
    		Genres: 'genres',
    		'Service Type': 'service_type',
    		'SC Type': 'sc_type',
    		Language: 'language',
    		'Provider Name': 'provider_name',
    		Director: 'director',
    		Releasedate: 'releasedate',
    		'Age Limit': 'age_limit',
    		'Shows Data Title': 'shows_data_title',
    		Rating: 'rating',
        LCN:'channel_lcn_number'
    	}
    	var str = this.filter.id
    	if (!this.filterData) {
    		return Vue.notify({ text: 'Please enter filer data', type: 'error' })
    	}
      const loader = Vue.$loading.show({ width: 40, height: 40 })
    	this.$http.get(config.menu.host + '/content/' + obj[str] + '/' + this.filterData).then(resp => {
        loader.hide()
    		let index = 0
    		this.contentData = resp.body.map(function (item) {
	          item.id = index++
	          item.sno = index
	          return item
	     	})
      }, err => {
        loader.hide()
    	})
    },
    edit (row) {
      this.Title = 'Update Content'
      this.content_id = row.content_id
      this.title = row.title
      this.genres = row.genres
      this.service_type = row.service_type
      this.sc_type = row.sc_type
      this.language = row.language
      this.director = row.director
      this.shows_data_title = row.shows_data_title
      this.age_limit = row.age_limit
      this.rating = row.rating
      this.channel_lcn_number = row.channel_lcn_number
      this.is_active = row.is_active
      this.is_banner = row.is_banner
      this.is_playable = row.is_playable
      this.is_home = row.is_home
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
    },
    search: debounce(function (term) {
    	this.term = term
    }, 400),
  },
}
</script>
